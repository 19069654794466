import Swiper from 'swiper';
import { Autoplay, EffectCoverflow, EffectFade, Navigation, HashNavigation, Keyboard } from 'swiper/modules';
import $ from 'jquery'

const args = {
  createElements: true,
  cssMode: true,
  modules: [ Autoplay, EffectCoverflow, EffectFade, Navigation, Keyboard ],
  keyboard: {
    enabled: true,
    onlyInViewport: false,
  },
  hashNavigation: true,
  navigation: {
    enabled: true,
  },
  loop: true,
}


if( $('body.home').length === 1 ){
  args.effect = 'fade'
  args.cssMode = false
  args.speed = 1000
  args.autoplay = { delay: 2000 }
  args.fadeEffect = { crossFade: false }
  args.navigation.enabled = false
} else {
  args.modules.push( HashNavigation )
}

const swiper = new Swiper( '.swiper', args );
$( function(){
  const $captions = $('.js-caption')
  $captions.on( 'click', function(){
    $captions.toggleClass( 'open' )
  })
})

swiper.navigation.disable()
addEventListener( "touchstart", swiper.navigation.disable, { passive: true } )
addEventListener( "mousemove", swiper.navigation.enable, { passive: true } )
